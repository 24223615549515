import React from 'react'
import GalleryBackground from '../../components/galleryBackground'
import GalleryGridFancy from '../../components/galleryGridFancy'
import newspaperRo from "../../images/rollovers/graphic-design/newspaperro.png"
import "../layout.css"

import img1 from '../../images/graphic-design/newspaper/1.jpg'
import img2 from '../../images/graphic-design/newspaper/2.jpg'
import img3 from '../../images/graphic-design/newspaper/3.jpg'
import img4 from '../../images/graphic-design/newspaper/4.jpg'
import img5 from '../../images/graphic-design/newspaper/5.jpg'
import img6 from '../../images/graphic-design/newspaper/6.jpg'
import img7 from '../../images/graphic-design/newspaper/7.jpg'
import img8 from '../../images/graphic-design/newspaper/8.jpg'
import img9 from '../../images/graphic-design/newspaper/9.jpg'
import img10 from '../../images/graphic-design/newspaper/10.jpg'
import img11 from '../../images/graphic-design/newspaper/11.jpg'
import img12 from '../../images/graphic-design/newspaper/12.jpg'
import img14 from '../../images/graphic-design/newspaper/14.jpg'
import img15 from '../../images/graphic-design/newspaper/15.jpg'
import img16 from '../../images/graphic-design/newspaper/16.jpg'
import img17 from '../../images/graphic-design/newspaper/17.jpg'
import img18 from '../../images/graphic-design/newspaper/18.jpg'
import img19 from '../../images/graphic-design/newspaper/19.jpg'

export default function Newspaper() {
    let images = [img6, img2, img7, img4, img5, img1, img3, img8, img9, img10, img11, img12, img14, img15, img16, img17, img18, img19]
    return (
        <GalleryBackground title="Newspaper" imgSrc={newspaperRo}>
            <GalleryGridFancy imgArray={images} />
        </GalleryBackground>
    )
}